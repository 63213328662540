import useFBContext from '../useFBContext';
import useLocales from '../useLocales';
import useStatsGuard from './useStatsGuard';

export default function useData(isBlockInbox) {
  const { FBUser } = useFBContext();

  const { translate: tLead } = useLocales('tool.lead');

  const { translate: tMessage } = useLocales('tool.messages');

  const { DAILY_LIMIT_INBOX } = useStatsGuard();

  const isPageProfile = FBUser?.isPageProfile;

  const CATEGORIZE_OPTIONS = [
    {
      label: tLead('categorize.seller'),
      value: 'seller',
    },
    {
      label: tLead('categorize.buyer'),
      value: 'buyer',
    },
    {
      label: tLead('categorize.unknown'),
      value: 'unknown',
    },
  ];

  const ACTION_OPTIONS = [
    {
      label: tLead('action.comment.title'),
      value: 'comment',
      levels: {
        1: {
          options: { keepOriginProduct: false },
          description: tLead('action.comment.level_1'),
        },
        2: {
          options: { keepOriginProduct: true },
          description: tLead('action.comment.level_2'),
        },
      },
      maxLevels: 2,
      // block: true,
    },
    ...(isPageProfile
      ? []
      : [
          {
            label: tLead('action.add_friend'),
            value: 'add_friend',
          },
          {
            label: tLead('action.inbox'),
            value: 'inbox',
            block: isBlockInbox,
            ...(isBlockInbox && { message: tMessage('limit_inbox', { amount: DAILY_LIMIT_INBOX }) }),
          },
        ]),
  ];

  const OPTION_DEFAULT = { categorize: [], actions: [] };

  return {
    ACTION_OPTIONS,
    CATEGORIZE_OPTIONS,
    OPTION_DEFAULT,
  };
}
