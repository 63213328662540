import _ from 'lodash';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { addNewUnCompletedForm } from '../redux/slices/form';
import { useSelector } from '../redux/store';

function useDraftForm(suffix = '') {
  // Path name for pre-save form data
  const { pathname } = useLocation();

  const PATH = suffix ? `${pathname}_${suffix}` : pathname;

  const draftFormTimer = useRef();

  const { unCompletedForms } = useSelector((state) => state.form);

  const getPreviousFormData = (suffix = '') => {
    if (unCompletedForms) {
      // Get any form data in same path we need custom
      const pathCustom = suffix ? `${pathname}_${suffix}` : pathname;
      const unCompletedData = unCompletedForms?.find((item) => item?.path === pathCustom);
      if (unCompletedData) {
        return unCompletedData?.formValues;
      }
    }
    return null;
  };

  // Detect change values of form
  const onFormDataChange = (newValue) => {
    if (draftFormTimer?.current) {
      clearTimeout(draftFormTimer.current);
    }
    draftFormTimer.current = setTimeout(() => {
      const unCompletedForm = {
        path: PATH,
        formValues: _.cloneDeep(newValue),
      };
      const index = unCompletedForms?.findIndex((item) => item?.path === unCompletedForm.path);
      if (index === -1 || (index !== -1 && !_.isEqual(unCompletedForm, unCompletedForms[index]))) {
        addNewUnCompletedForm(unCompletedForm);
      }
      clearInterval(draftFormTimer.current);
    }, 500);
  };

  return { getPreviousFormData, onFormDataChange };
}

export default useDraftForm;
