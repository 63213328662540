import axios from 'axios';
// config
import { HOST_API } from '../config';

export const handleUploadFiles = async (formData, callback) => {
  const accessToken = window.localStorage.getItem('accessToken');
  let fileUrl;
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const fileUpload = await axios({
      method: 'post',
      url: `${HOST_API}api/v1/assets/upload_image/`,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      data: formData,
    });
    if (fileUpload?.data?.message === 'OK') {
      fileUrl = fileUpload?.data?.url;
    }
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error(error);
  }
  return fileUrl;
};

export const uploadFile = async (files) => {
  const images = [];
  /* eslint-disable no-await-in-loop */
  files.forEach(async (file) => {
    if (typeof file === 'string') {
      images.push(file);
    } else {
      const formData = new FormData();
      formData.append('image', file);
      const data = await handleUploadFiles(formData);
      if (data) {
        images.push(data);
      }
    }
  });
  return images;
};

// Upload async files
export const asyncUploadFiles = async (files, callback) => {
  const listPromises = files.map((file) => {
    if (typeof file === 'string') {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        resolve(file);
        // Done
        callback();
      });
    }
    const formData = new FormData();
    formData.append('image', file);
    return handleUploadFiles(formData, callback);
  });

  const result = await Promise.all([...listPromises]);

  return result.filter((uri) => uri);
};

export const isFile = (input) => 'File' in window && input instanceof File;

export const isBlob = (input) => 'Blob' in window && input instanceof Blob;

export const getVideoThumbnail = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });

export const isVideo = (file) => {
  try {
    const extension = file?.fileExtension;
    const splited = file?.fileName?.split('.');
    const extensionFromName = splited[splited?.length - 1];
    const videoExtensions = [
      'mp4',
      'mov',
      'avi',
      'mkv',
      'flv',
      'wmv',
      'webm',
      'm4v',
      'mpg',
      'mpeg',
      '3gp',
      '3g2',
      'm2ts',
      'ts',
      'vob',
      'ogv',
      'f4v',
      'rm',
      'rmvb',
      'divx',
      'asf',
    ];
    return [extensionFromName, extension]?.some((ext) => videoExtensions?.indexOf(ext) !== -1);
  } catch (error) {
    return false;
  }
};

export const categorizeFiles = (files = []) => {
  return files?.reduce(
    (res, file) => {
      const isVideoType = isVideo(file);
      if (isVideoType) {
        res.videoFiles.push(file);
      } else {
        res.imageFiles.push(file);
      }
      return res;
    },
    { imageFiles: [], videoFiles: [] }
  );
};

export const convertFileToUint8Array = (file) =>
  new Promise((resolve) => {
    const fileExtension = (file?.type || 'unknown/unknown')?.split('/')[1];
    resolve({ objectURL: file?.preview, fileName: file?.name, size: file?.size, fileExtension });
  });

export function getMimeTypeFromUrl(url) {
  const imageMimeTypes = {
    bmp: 'image/bmp',
    gif: 'image/gif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    svg: 'image/svg+xml',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    webp: 'image/webp',
  };
  const parts = url.split('.');
  const extension = parts[parts.length - 1];
  return imageMimeTypes[extension];
}
