// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  forgetPassword: path(ROOTS_AUTH, '/forget-password'),
  resetPassword: path(ROOTS_AUTH, '/reset'),
  staffConfirm: path(ROOTS_AUTH, '/staff'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  email: {
    root: path(ROOTS_DASHBOARD, '/email'),
    list: path(ROOTS_DASHBOARD, '/email/list'),
  },
  campaign: {
    root: path(ROOTS_DASHBOARD, '/campaign'),
    multi: path(ROOTS_DASHBOARD, '/campaign/multi'),
    feed: path(ROOTS_DASHBOARD, '/campaign/feed'),
  },
  advertisement: {
    new: path(ROOTS_DASHBOARD, '/advertisement/new'),
    manage: path(ROOTS_DASHBOARD, '/advertisement/manage'),
  },
  quest: {
    root: path(ROOTS_DASHBOARD, '/quest'),
    list: path(ROOTS_DASHBOARD, '/quest/list'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  notification: {
    root: path(ROOTS_DASHBOARD, '/notification'),
    create: path(ROOTS_DASHBOARD, '/notification/create'),
  },
  staff: {
    root: path(ROOTS_DASHBOARD, '/staff'),
    list: path(ROOTS_DASHBOARD, '/staff/list'),
  },
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    payment: path(ROOTS_DASHBOARD, '/user/account?activeTab=payment'),
    sme: path(ROOTS_DASHBOARD, '/user/account?activeTab=business'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    notification: path(ROOTS_DASHBOARD, '/user/notification'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  post: {
    root: path(ROOTS_DASHBOARD, '/post'),
    list: path(ROOTS_DASHBOARD, '/post/list'),
    realEdit: (uuid) => path(ROOTS_DASHBOARD, `/post/real/${uuid}/edit`),
    socialEdit: (uuid) => path(ROOTS_DASHBOARD, `/post/social/${uuid}/edit`),
    webEdit: (uuid) => path(ROOTS_DASHBOARD, `/post/web/${uuid}/edit`),
    newRealEstate: path(ROOTS_DASHBOARD, '/post/real/new'),
    newSocial: path(ROOTS_DASHBOARD, '/post/social/new'),
    newWeb: path(ROOTS_DASHBOARD, '/post/web/new'),
  },
  account: {
    root: path(ROOTS_DASHBOARD, '/account'),
    list: path(ROOTS_DASHBOARD, '/account/list'),
  },
};

export const PATH_TOOL = {
  post: {
    root: '/post',
  },
  upPost: {
    root: '/up-post',
  },
  lead: {
    root: '/lead',
  },
  interactive: {
    root: '/interactive',
  },
  footer: {
    earn: '/affiliate',
    usage: 'https://doc.fbtool.net',
    faqs: 'https://doc.fbtool.net/cau-hoi-thuong-gap',
    support: 'https://zalo.me/4152692317049608912',
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
