import ReactGA from 'react-ga4';
import { createPostSocial } from '../../sections/@dashboard/post/helper';
import axiosInstance from '../axios';
import { obscureEmail } from '../formatString';
import { removeEmoji, shortTitleCustom } from '../others';

const FBTOOL_AI_MATCH_ENDPOINT = 'api/v2/tool/lead/match/';

export const getPaymentLink = async (data, type = 'subscription') => {
  try {
    const ecommerceItems = JSON.parse(localStorage.getItem('ecommerce_items'));
    const cartItem = ecommerceItems
      .filter((item) => item.item_id === data?.name)
      .find((element) => element !== undefined);
    // console.log('add_to_cart item', cartItem);
    // affiliate data is saved in ecommerce_items already
    ReactGA.event('add_to_cart', {
      items: [cartItem],
    });
    const response = await axiosInstance.post('api/v2/tool/payment/', {
      amount: data?.paymentAmount,
      domain: `${window.location.protocol}//${window.location.host}`,
      type,
      name: data?.name,
      source: data?.source || 'momo',
      refcode: data?.refcode,
      meta: { ...data },
    });
    if (response?.data?.message === 'OK') {
      ReactGA.event('begin_checkout', {
        items: [cartItem],
      });
      return { success: true, link: response.data.data.link };
    }
    return { success: false, error: 'Có lỗi xảy ra' };
  } catch (error) {
    console.error(error);
    return { success: false, error: error?.message };
  }
};

export const paymentCapture = async (orderId) => {
  try {
    const response = await axiosInstance.post('api/v2/tool/payment/capture/', {
      domain: `${window.location.protocol}//${window.location.host}`,
      order_id: orderId,
    });
    if (response?.data?.message === 'OK') {
      return { success: true, link: response.data.data.link };
    }
    return { success: false, error: 'Có lỗi xảy ra' };
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};

// eslint-disable-next-line arrow-body-style
export const removePostedContent = async (uuid) => {
  return await axiosInstance.delete(`api/v2/tool/assets/${uuid}/remove/`);
};

export const getLatestAssets = async () => {
  try {
    const {
      data: { results },
    } = await axiosInstance.get('api/v2/tool/assets/');
    let temp = results?.map((item) => {
      const { uuid, description } = item;
      return {
        uuid,
        title: description,
        description,
      };
    });
    // console.log(`setPostedContents %o`, temp);
    // reduce temp array to 5
    temp = temp.length > 5 ? temp.slice(0, 5) : temp;
    return [...temp];
  } catch (error) {
    console.log(error);
    return [];
  }
};

//  COLLECTIONS
export const getTargetCollections = async (type = 'group') => {
  try {
    const {
      data: { results },
    } = await axiosInstance.get(`api/v2/tool/collections/?type=${type}`);
    const temp = results?.map((item) => {
      const groups = item?.data?.split(',') || [];
      return {
        ...item,
        name: `${item?.name} (${groups?.length})`,
        groups,
      };
    });
    return [...temp];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const createTargetCollection = async (payload, type = 'group') => {
  const { data } = await axiosInstance.post(`api/v2/tool/collections/create/`, { ...payload, type });
  const groups = data?.data?.split(',') || [];
  return {
    ...data,
    name: `${data?.name} (${groups?.length})`,
    groups,
  };
};

// eslint-disable-next-line arrow-body-style
export const removeTargetCollection = async (id) => {
  return await axiosInstance.delete(`api/v2/tool/collections/${id}/remove/`);
};

export const createSocialContent = async (baseContent) => {
  try {
    const formatedContent = removeEmoji(baseContent)?.normalize('NFKC');

    const limitedShortTitle = shortTitleCustom(formatedContent, 50, 70);
    const payload = {
      description: baseContent,
      images: [],
      shortTitle: limitedShortTitle,
      converts: {},
    };
    const { isSuccess, response } = await createPostSocial(payload);
    if (isSuccess) {
      const {
        data: { uuid, description },
      } = response;
      const newContent = {
        uuid,
        title: description,
        description,
      };
      return newContent;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUserJobs = async (page = 1, paramsObj = {}) => {
  try {
    const searchParams = new URLSearchParams({ ...paramsObj, page });
    const url = `api/v2/tool/job/?${searchParams}`;
    const {
      data: { results, count, allIds },
    } = await axiosInstance.get(url);
    return { results, count, allIds };
  } catch (error) {
    console.log(error);
    return { results: [], count: 0, allIds: [] };
  }
};

export const getUserJobsWithIds = async (ids) => {
  try {
    const url = `api/v2/tool/job/list/`;
    const {
      data: { results },
    } = await axiosInstance.post(url, { ids });
    return results;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getEstimatedPostsWithIds = async (ids) => {
  try {
    const url = `api/v2/tool/job/estimate/`;
    const {
      data: { estimated_posts: estimatedPosts },
    } = await axiosInstance.post(url, { ids });
    return estimatedPosts || 0;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const syncUserJobs = async (payload) => {
  try {
    const { data } = await axiosInstance.post('api/v2/tool/job/sync/', payload);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

// LEADS

export const matchProduct = async (contents, leadContent, parent, keyword) => {
  try {
    if (!leadContent) {
      return { result: null };
    }
    const formatedContents = contents?.map((item, index) => ({ id: index, description: item?.description }));

    let customerNeed = {
      post: { content: leadContent },
      keyword,
    };

    if (parent) {
      const { content, keyword } = parent;
      customerNeed = {
        post: {
          content,
        },
        keyword,
        comment: leadContent,
      };
    }

    const { data } = await axiosInstance.post(FBTOOL_AI_MATCH_ENDPOINT, {
      products: formatedContents,
      customer_need: customerNeed,
    });
    const { most_relevant_products: matchedProducts } = data || {};

    if (matchedProducts?.length !== 0) {
      const result = contents?.reduce((res, item, index) => {
        const matchedIdx = matchedProducts?.findIndex((item) => item?.product_id === index);
        if (matchedIdx !== -1) {
          const { comment, greeting } = matchedProducts[matchedIdx];
          res.push({
            ...item,
            comment, // AI comment
            greeting, // AI greeting
          });
          return res;
        }
        return res;
      }, []);
      return { result };
    }

    return { result: null };
  } catch (error) {
    console.log('matchProduct:', error);
    const permissionMessage = error?.detail;
    const LIMIT_TEXT = ['giới hạn', 'limit'];
    const ABS_TEXT = 'AI';
    const isLimitAIRequest =
      permissionMessage &&
      LIMIT_TEXT.some((item) => permissionMessage?.indexOf(item) !== -1 && permissionMessage?.indexOf(ABS_TEXT) !== -1);
    return { result: null, isLimitAIRequest };
  }
};

export const getFacebookInfomation = async (uid) => {
  try {
    if (!uid) {
      return {};
    }

    const { data } = await axiosInstance.post('api/v2/tool/lead/profile/', {
      fbuid: uid,
    });

    const { status, data: result } = data || {};

    if (!status) {
      return {};
    }

    const { number, location, email, gender } = result || {};
    return { number, location, email, gender };
  } catch (error) {
    console.log('getFacebookNumber:', error);
    return {};
  }
};

export const getUserCommissions = async (page = 1, paramsObj = {}) => {
  try {
    const searchParams = new URLSearchParams({ ...paramsObj, page });
    const url = `api/v2/tool/affiliate/list?${searchParams}`;
    const {
      data: { results, count },
    } = await axiosInstance.get(url);

    const temp = results?.map((item) => {
      const {
        user: { first_name: firstName, last_name: lastName, last_login: lastLogin, email },
        package: { alias, amount: packageValue },
        amount,
        order_value: paymentValue,
        status,
        commission_info: { year: commissionReceiveYear, discount, commission_rate: commissionRate },
        created_at: createdAt,
      } = item || {};
      const fullName = obscureEmail(email);
      return {
        date: new Date(createdAt),
        name: fullName,
        lastOnline: new Date(lastLogin),
        packageValue,
        package: alias,
        discount: discount || 0,
        paymentValue: paymentValue || 0,
        amount: Number(amount),
        status,
        annualComission: Number(commissionReceiveYear),
      };
    });

    return { results: temp, count };
  } catch (error) {
    console.log(error);
    return { results: [], count: 0 };
  }
};

export const getUserCommissionStats = async () => {
  try {
    const url = `api/v2/tool/affiliate/stats`;
    const { data } = await axiosInstance.get(url);

    return data;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const updateAffiliateInfo = async (month, year, refcode) => {
  try {
    const url = `api/v2/tool/affiliate/discount/`;
    const { data } = await axiosInstance.post(url, { month, year, refcode });
    return { success: true, newRefcode: data?.new_refcode };
  } catch (error) {
    console.log(error);
    return { success: false, message: error?.message };
  }
};

export const sendWithdrawRequest = async () => {
  try {
    const url = `api/v2/tool/affiliate/withdraw/`;
    const { data } = await axiosInstance.post(url);
    return { success: true, data };
  } catch (error) {
    return { success: false, message: error?.message };
  }
};

export const updateBankInfo = async (payload) => {
  try {
    const url = `api/v2/tool/affiliate/bank/`;
    const config = {
      method: 'post',
      url,
      headers: { 'Content-Type': 'application/json' },
      data: payload,
    };

    const { data } = await axiosInstance(config);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
