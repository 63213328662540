import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { LeadProvider } from '../contexts/LeadContext';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// layouts
import SMTLayout from '../layouts/tool';
import Page404 from '../pages/Page404';
import { InteractiveProvider } from '../sections/whitelabel/sometool/interactive/context/InteractiveContext';
import { PostCommentProvider } from '../sections/whitelabel/sometool/primary_dashboard/context/PostCommentContext';
import { UpPostProvider } from '../sections/whitelabel/sometool/up_post/context/UpPostContext';
import { lazyRetry, Loadable } from './lazy';
import { PATH_TOOL } from './paths';
// =====================================================================

const PrimaryDashboard = Loadable(lazy(() => lazyRetry(() => import('../pages/whitelabel/sometool/PrimaryDashboard'))));
const InteractiveDashboard = Loadable(
  lazy(() => lazyRetry(() => import('../pages/whitelabel/sometool/InteractiveDashboard')))
);
const LeadDashboard = Loadable(lazy(() => lazyRetry(() => import('../pages/whitelabel/sometool/LeadDashboard'))));
const UpPost = Loadable(lazy(() => lazyRetry(() => import('../pages/whitelabel/sometool/UpPost'))));
const AffiliateDashboard = Loadable(
  lazy(() => lazyRetry(() => import('../pages/whitelabel/sometool/AffiliateDashboard')))
);

const smtRoutes = [
  // Main Routes
  {
    path: '*',
    element: <LogoOnlyLayout />,
    children: [
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  {
    path: '/',
    element: <SMTLayout />,
    children: [
      { element: <Navigate to={PATH_TOOL.post.root} replace />, index: true },
      {
        path: 'post',
        element: (
          <PostCommentProvider>
            <PrimaryDashboard />
          </PostCommentProvider>
        ),
        index: true,
      },
      {
        path: 'interactive',
        element: (
          <InteractiveProvider>
            <InteractiveDashboard />
          </InteractiveProvider>
        ),
        index: true,
      },
      {
        path: 'up-post',
        element: (
          <UpPostProvider>
            <UpPost />
          </UpPostProvider>
        ),
      },
      {
        path: 'lead',
        element: (
          <LeadProvider>
            <LeadDashboard />
          </LeadProvider>
        ),
      },
      {
        path: 'affiliate',
        element: <AffiliateDashboard />,
      },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
];

export default smtRoutes;
