import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
import useRefCustom from '../hooks/useRefCustom';

const initialState = {
  inboxSent: 0,
  isBlockInbox: false,
  DAILY_LIMIT_INBOX: 20,
  leadsCrawled: 0,
  isBlockCrawl: false,
  DAILY_LIMIT_CRAWL: 1000,
  increaseInboxSent: () => {},
  getCurrentInboxSent: () => {},
};

const STATS = {
  INBOX: 'inbox',
  CRAWL: 'crawl',
};

const StatsGuardContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
StatsGuardProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function StatsGuardProvider({ children }) {
  const { user, configs } = useAuth();

  const DAILY_LIMIT_INBOX = useMemo(() => configs?.daily_limit_lead_inbox || 20, [configs?.daily_limit_lead_inbox]);

  const DAILY_LIMIT_CRAWL = useMemo(() => configs?.daily_limit_lead_crawl || 1000, [configs?.daily_limit_lead_crawl]);

  const [stats, setStats, statsRef] = useRefCustom({
    [STATS.INBOX]: user?.inbox_sent || 0,
    [STATS.CRAWL]: user?.leads_crawled || 0,
  });

  useEffect(() => {
    setStats({ ...statsRef?.current, [STATS.INBOX]: user?.inbox_sent || 0, [STATS.CRAWL]: user?.leads_crawled || 0 });
  }, [user?.inbox_sent, user?.leads_crawled]);

  const increaseStatValue = (stat, amount = 1) => {
    const newValue = (statsRef?.current[stat] || 0) + amount;
    setStats({ ...statsRef?.current, [stat]: newValue });
  };

  const getCurrentStat = (stat) => {
    return statsRef?.current[stat];
  };

  const inboxSent = stats[STATS.INBOX];
  const isBlockInbox = useMemo(
    () => !!(inboxSent && DAILY_LIMIT_INBOX && inboxSent >= DAILY_LIMIT_INBOX),
    [inboxSent, DAILY_LIMIT_INBOX]
  );

  const increaseInboxSent = (amount = 1) => {
    if (!amount) {
      return;
    }
    increaseStatValue(STATS.INBOX, amount);
  };

  const leadsCrawled = stats[STATS.CRAWL];
  const isBlockCrawl = useMemo(
    () => !!(leadsCrawled && DAILY_LIMIT_CRAWL && leadsCrawled >= DAILY_LIMIT_CRAWL),
    [leadsCrawled, DAILY_LIMIT_CRAWL]
  );

  const increaseLeadsCrawled = (amount = 1) => {
    if (!amount) {
      return;
    }
    increaseStatValue(STATS.CRAWL, amount);
  };

  return (
    <StatsGuardContext.Provider
      value={useMemo(
        () => ({
          // Inbox
          inboxSent,
          isBlockInbox,
          increaseInboxSent,
          getCurrentInboxSent: () => getCurrentStat(STATS.INBOX),
          DAILY_LIMIT_INBOX,
          // Crawl
          leadsCrawled,
          isBlockCrawl,
          DAILY_LIMIT_CRAWL,
          increaseLeadsCrawled,
          getCurrentLeadsCrawled: () => getCurrentStat(STATS.CRAWL),
        }),
        [
          // eslint-disable-next-line react-hooks/exhaustive-deps
          stats,
          // inbox
          inboxSent,
          isBlockInbox,
          DAILY_LIMIT_INBOX,
          // crawl
          leadsCrawled,
          isBlockCrawl,
          DAILY_LIMIT_CRAWL,
        ]
      )}
    >
      {children}
    </StatsGuardContext.Provider>
  );
}

export { StatsGuardProvider, StatsGuardContext };
