import NoSleep from '@zakj/no-sleep';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import useBoolean from '../../../../../hooks/useBoolean';
import useRefCustom from '../../../../../hooks/useRefCustom';
import useToolContext from '../../../../../hooks/useToolContext';
import useToolFunction from '../../../../../hooks/useToolFunction';
import uuidv4 from '../../../../../utils/uuidv4';

const initialState = {
  comments: [],
  isRunning: false,
  postQueues: [],
  onCreateComment: () => {},
  onAppendComments: () => {},
  onRemoveCommentsByCollection: () => {},
  onChangeValue: () => {},
  onRemoveComment: () => {},
  onStartUpPost: () => {},
  onCancel: () => {},
  // Jobs
  jobs: [],
  updateJobs: () => {},
};

const MAXIMUM_COMMENTS = 20;

const CYCLE_DELAY = 15 * 60 * 1000;

const DEFAULT_COMMENTS = ['up', '.', 'Chấm', 'Lên', 'Giá', 'Inbox']?.map((item) => ({
  id: uuidv4(),
  text: item,
}));

const UpPostContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
UpPostProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function UpPostProvider({ children }) {
  const noSleep = new NoSleep();

  const [comments, setComments] = useState(DEFAULT_COMMENTS);

  const [jobs, setJobs] = useState([]);

  const { commentUpPosts, stopQueue } = useToolFunction();

  const { updateTaskRunningStatus } = useToolContext();

  const [postQueues, setPostQueues, postQueuesRef] = useRefCustom([]);

  const [, setUpTimes, upTimesRef] = useRefCustom(0);

  const isRunning = useBoolean();

  useEffect(() => {
    updateTaskRunningStatus(isRunning?.value);
  }, [isRunning?.value]);

  const onCreateComment = (comment) => {
    const temp = [...comments];
    temp.push({ id: uuidv4(), text: comment });
    setComments(temp?.slice(0, MAXIMUM_COMMENTS));
  };

  const onChangeValue = (id, newText) => {
    const temp = [...comments];
    const index = temp?.findIndex((item) => item?.id === id);
    if (index !== -1) {
      if (!newText && index < comments?.length - 1) {
        temp?.splice(index, 1);
      } else if (newText) {
        temp[index].text = newText;
      }
      setComments(temp);
    }
  };

  const onRemoveComment = (comment, checked) => {
    const temp = [...comments];
    const index = temp?.findIndex((item) => item?.id === comment?.id);
    if (index !== -1) {
      temp?.splice(index, 1);
      setComments(temp);
    }
  };

  const checkFinish = () => {
    const expectTimes = upTimesRef?.current;
    if (typeof expectTimes === 'number') {
      const result = [...postQueuesRef?.current] || [];
      const current = result.reduce((res, item) => res + (item?.results?.length || 0), 0);
      if (current >= expectTimes) {
        return true;
      }
    }
    return false;
  };

  const upPostCallback = (payload) => {
    const { assetUUID, groupId, link, success, isWaitingForApprove } = payload || {};

    const newArray = [...postQueuesRef?.current] || [];
    const index = newArray.findIndex((item) => item?.groupId === groupId);
    if (index !== -1) {
      newArray[index].results?.push({ success, assetUUID, link, isWaitingForApprove });
    }
    setPostQueues(newArray);
  };

  const onReset = () => {
    isRunning.onFalse();
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }
  };

  const taskRef = useRef();

  const loopTask = async (data) => {
    const { isOperationCanceled } = await commentUpPosts(data, upPostCallback);
    const isFinished = checkFinish();
    if (isOperationCanceled || isFinished) {
      console.log('Break cycle!! STOPPED.');
      onReset();
      return;
    }
    console.log('Run next cycle!');
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }

    taskRef.current = setTimeout(() => {
      loopTask(data);
    }, CYCLE_DELAY);
  };

  const onStartUpPost = (data) => {
    try {
      isRunning.onTrue();
      const { jobs, upTimes } = data;

      let expectTimes = 0;
      const groupIdsResult = jobs?.reduce((res, item) => {
        const temp = item.posts?.map((post) => {
          expectTimes += post?.postedUID?.length || 0;
          return { groupId: post?.groupId, results: [] };
        });
        return [...res, ...temp];
      }, []);
      console.log('total up', expectTimes * upTimes);
      setPostQueues(uniqBy(groupIdsResult, 'groupId'));
      setUpTimes(expectTimes * upTimes);
      loopTask(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onAppendComments = (id, newComments) => {
    const temp = [...comments];
    temp.push(...newComments?.map((comment) => ({ id: uuidv4(), text: comment, parentId: id })));
    setComments(temp?.slice(0, MAXIMUM_COMMENTS));
  };

  const onRemoveCommentsByCollection = (id) => {
    let temp = [...comments];
    temp = temp?.filter((item) => item?.parentId !== id);
    setComments(temp?.slice(0, MAXIMUM_COMMENTS));
  };

  const onCancel = () => {
    noSleep?.disable();
    stopQueue(false, 'manual');
    isRunning.onFalse();
    onReset();
  };

  // JOBS
  const updateJobs = (newJobs) => {
    if (!newJobs || newJobs?.length === 0) {
      return;
    }

    const temp = uniqBy([...newJobs], 'asset.uuid');
    setJobs(temp);
  };

  return (
    <UpPostContext.Provider
      value={useMemo(
        () => ({
          comments,
          isRunning: isRunning?.value,
          postQueues,
          jobs,
          onCreateComment,
          onChangeValue,
          onRemoveComment,
          onStartUpPost,
          onAppendComments,
          onRemoveCommentsByCollection,
          onCancel,
          updateJobs,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [comments, isRunning?.value, postQueues, jobs]
      )}
    >
      {children}
    </UpPostContext.Provider>
  );
}

export { UpPostProvider, UpPostContext };
