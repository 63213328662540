import { useCallback } from 'react';
import { isDisabledFeature } from '../utils/tenantUtils';
// hook
import useAuth from './useAuth';
// redux
import { useSelector } from '../redux/store';
import { SMT_DOMAIN } from '../config';
import { getMimeTypeFromUrl } from '../utils/fileUtils';

// ----------------------------------------------------------------------
export default function useTenant() {
  const { user } = useAuth();
  const { currentTenant } = useSelector((state) => state.tenant);

  const currentHost = window.location.host;

  const isSMT =
    (currentTenant?.whitelabel_domain && currentTenant.whitelabel_domain.indexOf(SMT_DOMAIN) !== -1) ||
    currentHost.indexOf(SMT_DOMAIN) !== -1;

  const checkFeatureDisabled = (feature) =>
    currentTenant ? isDisabledFeature(feature, user?.tenant_config?.deactivate_features) : false;

  const updatewhiteLabelInfo = useCallback(() => {
    if (currentTenant && currentTenant?.whitelabel_domain) {
      const headContents = currentTenant?.config?.seo_infomation?.head || [];

      if (currentTenant?.config?.logo) {
        // <link rel="icon" type="image/png" href="%PUBLIC_URL%/favicon/ress.png">
        const mimeType = getMimeTypeFromUrl(currentTenant?.config?.logo);
        if (mimeType) {
          headContents.push({
            tag: 'link',
            attributes: {
              rel: 'icon',
              type: mimeType,
              href: currentTenant?.config?.logo,
            },
          });
        }
      }

      headContents?.forEach((item) => {
        const { tag, content: innerText, attributes } = item || {};
        switch (tag) {
          case 'meta': {
            const { name, property, content } = attributes || {};
            if ((name || property) && content) {
              let currentEle;
              if (name) {
                currentEle = document.querySelector(`head meta[name="${name}"]`);
              } else {
                currentEle = document.querySelector(`head meta[property="${property}"]`);
              }
              if (currentEle) {
                Object.keys(attributes).forEach((attribute) => {
                  currentEle.setAttribute(attribute, attributes[attribute]);
                });
              } else {
                const newEle = document.createElement(tag);
                Object.keys(attributes).forEach((attribute) => {
                  newEle.setAttribute(attribute, attributes[attribute]);
                });
                document.head.appendChild(newEle);
              }
            }
            break;
          }
          case 'title': {
            if (!innerText) {
              break;
            }
            const currentEle = document.querySelector(`head title`);
            if (currentEle) {
              currentEle.textContent = innerText;
            } else {
              const newEle = document.createElement(tag);
              newEle.textContent = innerText;
              document.head.appendChild(newEle);
            }
            break;
          }
          case 'link': {
            if (attributes?.rel === 'icon') {
              const iconElements = document.querySelectorAll('head link[rel="icon"]');
              iconElements.forEach((item) => {
                item.remove();
              });
            }
            const newEle = document.createElement(tag);
            Object.keys(attributes).forEach((attribute) => {
              newEle.setAttribute(attribute, attributes[attribute]);
            });
            document.head.appendChild(newEle);
            break;
          }
          default:
            break;
        }
      });
    }
  }, [currentTenant]);

  return {
    checkFeatureDisabled,
    isSMT,
    surfixDomain:
      currentTenant?.config?.seo_infomation?.surfix_domain || currentTenant?.whitelabel_domain || 'Uptin.vn',
    updatewhiteLabelInfo,
  };
}
