// @mui
import { enUS, viVN } from '@mui/material/locale';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const POST_SUCCESS_COST = 500;

export const IS_LOCALHOST = window.location.origin?.indexOf('localhost') !== -1;

export const SMT_DOMAIN = process.env.REACT_APP_SMT_DOMAIN || 'fbtool.net';

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || '';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'dev';

export const IS_PRODUCTION = ENVIRONMENT === 'production';

export const WEBSOCKET_TIMEOUT = process.env.REACT_APP_WEBSOCKET_TIMEOUT
  ? parseInt(process.env.REACT_APP_WEBSOCKET_TIMEOUT, 10)
  : 5;
export const ENABLED_DETECT_NUDE = process.env.REACT_APP_ENABLED_DETECT_NUDE || false;
// One minutes gap between pings
export const PING_TIMER = 1 * 60 * 1000;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'blue',
  themeStretch: false,
};

// HAMBURGER BUTTON COLLAPSE
export const defaultCollapse = {
  click: true,
  hover: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Vietnamese',
    value: 'vn',
    systemValue: viVN,
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: frFR,
  //   icon: '/assets/icons/flags/ic_flag_fr.svg',
  // },
  // {
  //   label: 'Chinese',
  //   value: 'cn',
  //   systemValue: zhCN,
  //   icon: '/assets/icons/flags/ic_flag_cn.svg',
  // },
  // {
  //   label: 'Arabic (Sudan)',
  //   value: 'ar',
  //   systemValue: arSD,
  //   icon: '/assets/icons/flags/ic_flag_sa.svg',
  // },
];

export const defaultLang = allLangs[0]; // English

export const MB = [
  '02',
  '04',
  '06',
  '08',
  '10',
  '11',
  '12',
  '14',
  '15',
  '17',
  '19',
  '20',
  '22',
  '24',
  '25',
  '26',
  '27',
  '30',
  '33',
  '34',
  '35',
  '36',
  '37',
  '01',
  '31',
];

export const MT = [
  '40',
  '42',
  '44',
  '45',
  '49',
  '51',
  '52',
  '54',
  '58',
  '60',
  '62',
  '64',
  '68',
  '38',
  '46',
  '48',
  '56',
  '66',
  '67',
];

export const MN = [
  '70',
  '72',
  '74',
  '75',
  '79',
  '80',
  '82',
  '83',
  '84',
  '86',
  '87',
  '89',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '77',
];

// TENANT ROUTES & FEATURE TO CHECK DISABLE
export const FEATURE_SCHEDULE_BDS = 'schedule@bds';
export const FEATURE_SCHEDULE_MXH = 'schedule@mxh';
export const FEATURE_SCHEDULE_WEB = 'schedule@web';
export const FEATURE_CHANGE_PASSWORD = 'change_password';
export const FEATURE_PAYMENT = 'payment';
export const FEATURE_ASSET_CREATE = 'asset@create';
export const FEATURE_ASSET_EDIT = 'asset@edit';
export const FEATURE_SITE_CREATE = 'site@add';
export const FEATURE_SITE_EDIT = 'site@edit';
export const FEATURE_SOCIAL_LINK = 'social@link';
export const FEATURE_GPT = 'gpt';
export const FEATURE_ASSET_CLONE = 'asset@clone';

export const FEATURES_SCHEDULE_NAME_MAPPING = {
  schedule: {
    BĐS: FEATURE_SCHEDULE_BDS,
    MXH: FEATURE_SCHEDULE_MXH,
    WEB: FEATURE_SCHEDULE_WEB,
  },
};

export const IMAGE_ID_PREFIX = 'detect_';

export const FLAG_KEY = '{{RENDER_POINT}}';
