import { useRoutes } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import useTenant from '../hooks/useTenant';
import { routesToPath } from '../utils/tenantUtils';
import smtRoutes from './smt-route';
import uptinRoutes from './uptin-route';

// ----------------------------------------------------------------------

export default function Router({ deactivateRoutes = [] }) {
  // ====================== DETECT SOME TENANT ROUTES =======================

  const { user } = useAuth();

  let { isSMT } = useTenant();
  if (window.location.origin?.indexOf('localhost') !== -1) {
    isSMT = true;
  }

  const isAdminTenant = user?.package_using?.is_admin || false;

  // Only admin of package
  if (!isAdminTenant) {
    deactivateRoutes.push('/staff/list');
  }
  // ====================== END =======================

  const routes = isSMT ? smtRoutes : uptinRoutes;
  return useRoutes(routesToPath(routes, deactivateRoutes));
}
