import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import useAuth from '../hooks/useAuth';

// hooks
import useRefCustom from '../hooks/useRefCustom';
import useToolFunction from '../hooks/useToolFunction';
import { parseJSON } from '../utils/others';

const VIDEO_UPLOAD_CONFIGS = 'video-upload-configs';

const initialState = {
  FBUser: {},
  groupsData: [],
  formatPresets: [],
  // Video
  videoConfigs: {},
  // Main funcs
  checkExtensionActive: () => {},
  getExtensionVersion: () => {},
  postToGroupsWithMultiContent: () => {},
  postSeedingComments: () => {},
  stopQueue: () => {},
  getSearchPosts: () => {},
  leadsSeeding: () => {},
  getPostsInGroupByKeyword: () => {},
  getCommentsFromFeedback: () => {},
  commentUpPosts: () => {},
  ACTIONS_DELAY: {},
};

const FBContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
FBProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function FBProvider({ children }) {
  const {
    getGroupsJoined,
    getProfile,
    updateFBData,
    getTextFormatPresets,
    checkExtensionActive,
    getExtensionVersion,
    postToGroupsWithMultiContent,
    postSeedingComments,
    stopQueue,
    getSearchPosts,
    leadsSeeding,
    getPostsInGroupByKeyword,
    getCommentsFromFeedback,
    commentUpPosts,
    ACTIONS_DELAY,
    // getRepliesFromComment,
  } = useToolFunction(
    () => {},
    () => {}
  );

  const { user: userAuth } = useAuth();

  const [user, setUserRef, userRef] = useRefCustom(null);

  useEffect(() => {
    setUserRef(userAuth);
  }, [userAuth]);

  // Facebook data
  const [FBUser, setFBUser] = useState(null);

  const [groupsData, setGroupsData, groupsDataRef] = useRefCustom([]);

  const [formatPresets, setFormatPresets, formatPresetsRef] = useRefCustom([]);

  const [videoConfigs, setVideoConfigs] = useState(null);

  //  ---------------------------------------------------------------------

  // Funcs get data of Facebook
  const onGetProfile = async () => {
    const profile = await getProfile();
    setFBUser(profile);
  };

  const onGetGroupsJoined = async () => {
    const groupsJoined = await getGroupsJoined();
    setGroupsData(groupsJoined);
  };

  const onGetTextFormatPresets = async () => {
    const formatPresetsData = await getTextFormatPresets();
    setFormatPresets(formatPresetsData);
  };

  useEffect(() => {
    (async () => {
      const fbInfo = localStorage.getItem('fb_info');
      if (fbInfo) {
        try {
          const data = JSON.parse(fbInfo);
          if (data) {
            await updateFBData(data);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        await updateFBData();
      }
      await onGetProfile();
      if (groupsDataRef?.current?.length === 0) {
        await onGetGroupsJoined();
      }
      if (formatPresetsRef?.current?.length === 0) {
        await onGetTextFormatPresets();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //  ---------------------------------------------------------------------

  return (
    <FBContext.Provider
      value={useMemo(
        () => ({
          FBUser,
          groupsData,
          formatPresets,
          // Video
          videoConfigs,
          // Main funcs
          checkExtensionActive,
          getExtensionVersion,
          postToGroupsWithMultiContent,
          postSeedingComments,
          stopQueue,
          getSearchPosts,
          leadsSeeding,
          getPostsInGroupByKeyword,
          getCommentsFromFeedback,
          commentUpPosts,
          ACTIONS_DELAY,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [FBUser, groupsData, formatPresets, videoConfigs, ACTIONS_DELAY]
      )}
    >
      {children}
    </FBContext.Provider>
  );
}

export { FBProvider, FBContext };
