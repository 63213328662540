/* eslint-disable */

import { fDateTime, reduceMinutes } from '../formatTime';
import { convertIdToFeedBackId, convertToMD5 } from '../others';
import uuidv4 from '../uuidv4';

/**
 * Helper class to call api to facebook.
 */
export default class FBTool {
  FACEBOOK_API = 'https://www.facebook.com/api/graphql/';

  ACTIONS = {
    POST_TO_GROUP: 'POST_TO_GROUP',
    POST_COMMENT: 'POST_COMMENT',
  };

  PATH_AFTER_UPLOADED = {
    POST_TO_GROUP: ['variables', 'input', 'attachments'],
    POST_COMMENT: ['variables', 'input', 'attachments'],
  };

  ITEM_STRUCTURE = {
    POST_TO_GROUP: {
      photo: {
        id: 'imageId',
      },
    },
    POST_COMMENT: {
      media: {
        id: 'imageId',
      },
    },
  };

  /**
   * constructor of class
   * @param {array} cookies
   * @param {string} fbDtsg
   * @param {string} dyn
   */
  constructor(cookies = [], fbDtsg = '', dyn = '', otherData = {}, accessToken = null) {
    this.cookies = cookies?.reduce((res, item) => {
      res[item.name] = item?.value;
      return res;
    }, {});
    this.cookiesOrigin = cookies;
    this.fbDtsg = fbDtsg;
    this.dyn = dyn;
    this.otherData = otherData || {};
    this.accessToken = accessToken;
  }

  getFilesUploadStructure(files, action = this.ACTIONS.POST_TO_GROUP) {
    try {
      const itemStructure = JSON.stringify(this.ITEM_STRUCTURE[action]);
      return { files, pathAfterUploaded: this.PATH_AFTER_UPLOADED[action], structure: itemStructure };
    } catch (error) {
      return {
        files,
        pathAfterUploaded: this.PATH_AFTER_UPLOADED[this.ACTIONS.POST_TO_GROUP],
        structure: JSON.stringify(this.ITEM_STRUCTURE[this.ACTIONS.POST_TO_GROUP]),
      };
    }
  }

  /**
   * Get profile request data.
   * @return {object}
   */
  getProfile() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __comet_req: 15,
        fb_dtsg: this.fbDtsg,
        variables: { scale: 1.5 },
        server_timestamps: true,
        doc_id: 6434758246628736,
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get text format presets
   * @return {object}
   */
  getTextFormatPresetsPayload() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        fb_dtsg: this.fbDtsg,
        __dyn: this.dyn,
        variables: { containsCustomSwatchCategory: false, scale: 1 },
        server_timestamps: true,
        doc_id: 9847007025340314,
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get group pending participate approval
   * @return {object}
   */
  getParticipageApprovalPayload(groupId) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        fb_dtsg: this.fbDtsg,
        __dyn: this.dyn,
        variables: { groupID: groupId },
        server_timestamps: true,
        doc_id: 7120999354638317,
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get groups joined request data.
   * @return {object}
   */
  getGroupsJoined() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        doc_id: 3884641628300421,
        variables: {
          adminGroupsCount: 5000,
          memberGroupsCount: 5000,
          scale: 1.5,
          count: 10,
          cursor: null,
        },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get list friends
   * @return {object}
   */
  getFriends() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        doc_id: 4936483286421335,
        av: this.cookies?.i_user || this.cookies?.c_user,
        dpr: 1,
        __a: 1,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        server_timestamps: true,
        variables: { id: this.cookies?.i_user || this.cookies?.c_user, query: '', scale: 1 },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get list friends
   * @return {object}
   */
  getAccessToken() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        app_id: 124024574287414,
        redirect_uri: `fbconnect://success`,
        display: `page`,
        from_post: 1,
        return_format: `access_token`,
        _CONFIRM: 1,
      });

      return {
        endpoint: `https://www.facebook.com/v1.0/dialog/oauth/confirm`,
        method: 'POST',
        headers,
        payload,
        returnRaw: true,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get reactions in post
   * @return {object}
   */
  getPayloadReactionsInPost(groupPostFormatId, cursor = '') {
    try {
      const headers = this.getHeaders();
      const paramsObj = {
        limit: 1000,
        fields: 'id',
        access_token: this.accessToken,
        after: cursor,
      };

      const searchParams = new URLSearchParams(paramsObj);

      return {
        endpoint: `https://graph.facebook.com/v2.6/${groupPostFormatId}/reactions?${searchParams}`,
        method: 'GET',
        headers,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get reactions from feedback
   * @return {object}
   */
  getPayloadReactionsFromFeedback(feedbackId, cursor = null) {
    try {
      const headers = this.getHeaders();
      const pageId = this.cookies?.i_user || this.cookies?.c_user;

      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: pageId,
        __user: pageId,
        __a: 1,
        dpr: 1,
        __req: 15,
        __comet_req: 15,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'CometUFIReactionsDialogTabContentRefetchQuery',
        variables: {
          count: 10,
          cursor,
          feedbackTargetID: feedbackId,
          reactionID: null,
          scale: 1,
          id: feedbackId,
        },
        server_timestamps: true,
        doc_id: '7443538865752313',
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get comments info in post
   * @return {object}
   */
  getPayloadListPosts(endpoint, start, end) {
    try {
      const headers = this.getHeaders();
      const paramsObj = {
        limit: 100,
        fields: 'id',
        access_token: this.accessToken,
        ...(start && { since: start }),
        ...(end && { until: end }),
      };

      const searchParams = new URLSearchParams(paramsObj);

      return {
        endpoint:
          endpoint ||
          `https://graph.facebook.com/v2.6/${this.cookies?.i_user || this.cookies?.c_user}/posts?${searchParams}`,
        method: 'GET',
        headers,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get comments info in post
   * @return {object}
   */
  getPayloadCommentInPost(groupPostFormatId, afterComments = '', inputIsFeedback = false) {
    try {
      const feedbackId = inputIsFeedback ? groupPostFormatId : convertIdToFeedBackId(groupPostFormatId);
      const headers = this.getHeaders();
      const payload = {
        doc_id: 3874089129331862,
        av: this.cookies?.i_user || this.cookies?.c_user,
        fb_api_caller_class: 'RelayModern',
        dpr: 1,
        __a: 1,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        server_timestamps: true,
        variables: {
          max_comments: 50,
          after_comments: afterComments,
          feedback_id: feedbackId,
          scale: 1,
        },
        fb_dtsg: this.fbDtsg,
      };

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get list chat messengers
   * @return {object}
   */
  getPayloadReadMessages() {
    try {
      const headers = this.getHeaders();
      const payload = {
        q: `viewer(){message_threads{nodes{thread_key{thread_fbid,other_user_id},messages_count,thread_type,updated_time_precise}}}`,
        fb_dtsg: this.fbDtsg,
      };

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Post to groups
   * @param {object} data
   */
  getPayloadPostToGroup(data) {
    try {
      const { groupId, images, video, content, link, textFormatPresetId, composerSessionId } = data;
      const actorId = this.cookies?.i_user || this.cookies?.c_user;
      const headers = {
        accept: '*/*',
        'accept-language': `en-US,en;q=0.9`,
        'cache-control': `no-cache`,
        'content-type': `application/x-www-form-urlencoded`,
        cookie: this.getCookieString(),
        origin: `https://www.facebook.com`,
        pragma: `no-cache`,
        priority: `u=1, i`,
        referer: `https://www.facebook.com`,
        'sec-ch-prefers-color-scheme': `light`,
        'sec-ch-ua': `"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"`,
        'sec-ch-ua-full-version-list': `"Not/A)Brand";v="8.0.0.0", "Chromium";v="126.0.6478.183", "Google Chrome";v="126.0.6478.183"`,
        'sec-ch-ua-mobile': `?0`,
        'sec-ch-ua-model': '',
        'sec-ch-ua-platform': 'Windows',
        'sec-ch-ua-platform-version': '10.0.0',
        'sec-fetch-dest': `empty`,
        'sec-fetch-mode': `cors`,
        'sec-fetch-site': `same-origin`,
        'user-agent': `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36`,
        'x-asbd-id': `129477`,
        'x-fb-friendly-name': `ComposerStoryCreateMutation`,
      };
      const payload = this.getPayload({
        doc_id: 7828891083898161,
        fb_api_req_friendly_name: 'ComposerStoryCreateMutation',
        server_timestamps: true,
        fb_api_caller_class: 'RelayModern',
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        av: actorId,
        __aaid: 0,
        __user: actorId,
        __a: 1,
        variables: {
          input: {
            composer_entry_point: 'inline_composer',
            composer_source_surface: 'group',
            composer_type: 'group',
            logging: { composer_session_id: composerSessionId || uuidv4() },
            source: 'WWW',
            message: { ranges: [], text: content },
            with_tags_ids: null,
            inline_activities: [],
            explicit_place_id: '0',
            text_format_preset_id: '0',
            tracking: [null],
            event_share_metadata: { surface: 'newsfeed' },
            audience: { to_id: groupId },
            actor_id: actorId,
            client_mutation_id: '1',
          },
          feedLocation: 'GROUP',
          feedbackSource: 0,
          focusCommentID: null,
          gridMediaWidth: null,
          groupID: null,
          scale: 1,
          privacySelectorRenderLocation: 'COMET_STREAM',
          checkPhotosToReelsUpsellEligibility: false,
          renderLocation: 'group',
          useDefaultActor: false,
          inviteShortLinkKey: null,
          isFeed: false,
          isFundraiser: false,
          isFunFactPost: false,
          isGroup: true,
          isEvent: false,
          isTimeline: false,
          isSocialLearning: false,
          isPageNewsFeed: false,
          isProfileReviews: false,
          isWorkSharedDraft: false,
          hashtag: null,
          canUserManageOffers: false,
          __relay_internal__pv__CometUFIShareActionMigrationrelayprovider: true,
          __relay_internal__pv__IncludeCommentWithAttachmentrelayprovider: true,
          __relay_internal__pv__CometUFIReactionsEnableShortNamerelayprovider: false,
          __relay_internal__pv__CometImmersivePhotoCanUserDisable3DMotionrelayprovider: false,
          __relay_internal__pv__IsWorkUserrelayprovider: false,
          __relay_internal__pv__IsMergQAPollsrelayprovider: false,
          __relay_internal__pv__StoriesArmadilloReplyEnabledrelayprovider: true,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
      });

      // Images
      if (images) {
        const photoPayload = images
          ?.filter((item) => item)
          ?.map((imageId) => ({
            photo: {
              id: imageId,
            },
          }));
        payload.variables.input.attachments = photoPayload;
      }

      // Video
      if (video) {
        const videoPayload = video
          ?.filter((item) => item)
          ?.map((videoId) => ({ video: { id: videoId, notify_when_processed: true } }));
        payload.variables.input.attachments = [...(payload?.variables?.input?.attachments || []), ...videoPayload];
      }

      // Link
      if (link) {
        payload.variables.input.attachments.push({ link });
      }

      // Preset id (banner)
      if (textFormatPresetId) {
        payload.variables.input.text_format_preset_id = textFormatPresetId;
      }

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
        options: {
          redirect: 'manual',
        },
        extraData: { userCookie: this.cookiesOrigin, userFBdtsg: this.fbDtsg, userDyn: this.dyn },
        // ...(!isOldVersion && { uploadImages: this.getFilesUploadStructure(files) }),
      };
    } catch (error) {
      console.log('content', error, error?.message);
      return null;
    }
  }

  /**
   *
   * @param {*} matrix
   * @return {Array}
   */
  generateCombinations(matrix) {
    const combinations = [];

    /**
     *
     * @param {Object} currentCombination
     * @param {Array} keys
     */
    function generate(currentCombination, keys) {
      if (keys.length === 0) {
        combinations.push(currentCombination);
        return;
      }

      const currentKey = keys[0];
      const values = matrix[currentKey];

      for (const value of values) {
        const newCombination = { ...currentCombination, [currentKey]: value };
        generate(newCombination, keys.slice(1));
      }
    }

    generate({}, Object.keys(matrix));
    return combinations;
  }

  /**
   * Post comment to feedback
   * @param {string} feedbackId
   * @param {object} data
   */
  getPayloadPostComment(feedbackId, data) {
    try {
      console.log('postComment', new Date());
      const { text, images } = data || {};

      const headers = this.getHeaders();
      const actorId = this.cookies?.i_user || this.cookies?.c_user;
      const extraPayload = {
        doc_id: 3988300734617853,
        variables: {
          displayCommentsFeedbackContext: null,
          displayCommentsContextEnableComment: null,
          displayCommentsContextIsAdPreview: null,
          displayCommentsContextIsAggregatedShare: null,
          displayCommentsContextIsStorySet: null,
          feedLocation: 'GROUP',
          feedbackSource: 0,
          focusCommentID: null,
          groupID: null,
          includeNestedComments: false,
          input: {
            attachments: null,
            feedback_id: feedbackId,
            formatting_style: null,
            message: { ranges: [], text },
            reply_target_clicked: false,
            feedback_source: 'PROFILE',
            actor_id: actorId,
            client_mutation_id: '18',
          },
          inviteShortLinkKey: null,
          renderLocation: null,
          scale: 1,
          useDefaultActor: false,
          UFI2CommentsProvider_commentsKey: 'CometGroupDiscussionRootSuccessQuery',
          __relay_internal__pv__GroupsCometDelayCheckBlockedUsersrelayprovider: false,
        },
        av: actorId,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
      };

      // Files attachments
      if (images) {
        const mediaPayload = images
          ?.filter((item) => item)
          ?.map((mediaId) => ({
            media: {
              id: mediaId,
            },
          }));
        extraPayload.variables.input.attachments = mediaPayload;
      }

      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
        options: {
          redirect: 'manual',
        },
        extraData: { userCookie: this.cookiesOrigin, userFBdtsg: this.fbDtsg, userDyn: this.dyn },
        // ...(!isOldVersion && { uploadImages: this.getFilesUploadStructure(files, this.ACTIONS.POST_COMMENT) }),
      };
    } catch (error) {
      console.log('comment', error, error?.message);
      return null;
    }
  }

  /**
   * Get list spin comments
   * @param {Array} comments
   * @param {Array} groupIds
   * @return {Array}
   */
  getSpinComments(comments, groupIds) {
    try {
      const sourceSpinned = comments?.map((item) => ({
        ...item,
        spin: this.generateCombinations(item?.data?.spinContents || {}),
      }));

      const results = groupIds?.reduce((res) => {
        const temp = [];
        sourceSpinned.forEach((item) => {
          let modifiedContent = item?.data?.description;
          if (item?.spin?.length !== 0 && item?.data?.spinContents) {
            const spinData = item?.spin?.pop();
            // Update head
            item?.spin?.unshift(spinData);
            Object.keys(spinData).forEach((key) => {
              modifiedContent = modifiedContent.replace(`{{${key}}}`, spinData[key]);
            });
          }
          temp.push({ text: modifiedContent, files: item?.files });
        });
        res.push(temp);
        return res;
      }, []);

      return results;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * Get site info with link
   * @param {string} url
   */
  getPayloadSiteInfo(url) {
    try {
      const headers = this.getHeaders();
      const actorId = this.cookies?.i_user || this.cookies?.c_user;
      const extraPayload = {
        doc_id: 5012066348923062,
        variables: {
          feedLocation: 'FEED_COMPOSER',
          focusCommentID: null,
          goodwillCampaignId: '',
          goodwillCampaignMediaIds: [],
          goodwillContentType: null,
          params: { url },
          privacySelectorRenderLocation: 'COMET_COMPOSER',
          renderLocation: 'composer_preview',
          parentStoryID: null,
          scale: 1,
          useDefaultActor: false,
          shouldIncludeStoryAttachment: false,
          __relay_internal__pv__FBReelsEnableDeferrelayprovider: true,
        },
        av: actorId,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
      };

      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.error('getSiteInfo', error);
      return null;
    }
  }

  /**
   * split JSON from FB
   * @param {*} text
   * @return {object}
   */
  splitJSON(text) {
    try {
      const json = JSON.parse(text);
      return json;
    } catch (error) {
      console.log('FB sometimes return multiple JSON merge with space between');
      const whitespaces = [
        '} {',
        '}\n{',
        `}
        {`,
      ];
      // for each whitespace item, loop to replace text string
      whitespaces.forEach((item) => {
        text = text.replace(item, '}{');
      });
      const split = text.split('}{');
      const newText = split[0] + '}';
      // console.log('newText', newText);
      const repaired = jsonrepair(newText);
      // console.log('repaired', repaired);
      const json = JSON.parse(repaired);
      // console.log('json', json);
      return json[0];
    }
  }

  /**
   * Get feedBackId
   * @param {string} groupId
   * @param {Number} amount
   * @param {string} cursor
   */
  getFeedbackPayload(groupId, cursor = null) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        doc_id: 7591309714238821,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __comet_req: 15,
        variables: {
          count: 3,
          ...(cursor && { cursor }),
          feedLocation: 'GROUP',
          feedType: 'DISCUSSION',
          feedbackSource: 0,
          focusCommentID: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'group',
          scale: 1,
          sortingSetting: 'CHRONOLOGICAL',
          stream_initial_count: 10,
          useDefaultActor: false,
          id: groupId,
          __relay_internal__pv__StoriesRingrelayprovider: false,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get search posts in group payload
   * @param {string} groupId
   * @param {string} keyword
   * @param {string} cursor
   */
  getSearchPostsInGroupPayload(groupId, keyword, cursor = null) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        doc_id: 7165653280200780,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: {
          allow_streaming: false,
          args: {
            callsite: 'comet:scoped:group',
            config: {
              exact_match: false,
              high_confidence_config: null,
              intercept_config: null,
              sts_disambiguation: null,
              watch_config: null,
            },
            experience: {
              encoded_server_defined_params: null,
              fbid: groupId,
              type: 'GROUPS_SCOPED',
            },
            filters: ['{"name":"chronosort","args":""}'],
            text: keyword,
          },
          count: 5,
          cursor,
          feedLocation: 'SEARCH',
          feedbackSource: 23,
          fetch_filters: true,
          focusCommentID: null,
          locale: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'group_serp',
          scale: 1,
          stream_initial_count: 0,
          useDefaultActor: false,
          __relay_internal__pv__StoriesRingrelayprovider: false,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get posts on facebook search engine
   */
  getSearchPostsPayload(data) {
    try {
      const { options, keyword, cursor } = data;
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        server_timestamps: true,
        doc_id: 7677380942309792,
        variables: {
          count: 5,
          ...(cursor && { cursor }),
          allow_streaming: false,
          args: {
            callsite: 'COMET_GLOBAL_SEARCH',
            config: {
              exact_match: false,
              high_confidence_config: null,
              intercept_config: null,
              sts_disambiguation: null,
              watch_config: null,
            },
            experience: {
              client_defined_experiences: [],
              encoded_server_defined_params: null,
              fbid: null,
              type: 'POSTS_TAB',
            },
            filters: [],
            text: keyword,
          },
          feedbackSource: 23,
          fetch_filters: true,
          renderLocation: 'search_results_page',
          scale: 1,
          stream_initial_count: 0,
          useDefaultActor: false,
        },
      };

      const { recentPost } = options || {};

      const filters = [];

      // Is recent posts
      if (recentPost) {
        filters.push('{"name":"recent_posts","args":""}');
      }

      if (filters?.length !== 0) {
        const formated = filters?.join(`","`);
        extraPayload.variables.args.filters.push(formated);
      }

      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Get comments from feedback
   */
  getCommentsFromFeedbackPayload(data) {
    try {
      const { feedbackId, cursor } = data;
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        server_timestamps: true,
        doc_id: 7784410118290185,
        variables: {
          commentsAfterCount: -1,
          commentsAfterCursor: null,
          commentsBeforeCount: null,
          commentsBeforeCursor: null,
          ...(cursor && { commentsAfterCursor: cursor }),
          commentsIntentToken: 'CHRONOLOGICAL_UNFILTERED_INTENT_V1',
          feedLocation: 'DEDICATED_COMMENTING_SURFACE',
          focusCommentID: null,
          scale: 1,
          useDefaultActor: false,
          id: feedbackId,
        },
      };

      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Get comments from feedback
   */
  getRepliesOfCommentPayload(data) {
    try {
      const { feedbackId, expansionToken, cursor } = data;
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        server_timestamps: true,
        doc_id: 7362257087232994,
        variables: {
          clientKey: null,
          expansionToken,
          feedLocation: 'GROUP_PERMALINK',
          focusCommentID: null,
          repliesAfterCount: null,
          repliesAfterCursor: null,
          repliesBeforeCount: null,
          repliesBeforeCursor: null,
          ...(cursor && { repliesAfterCursor: cursor }),
          scale: 1,
          useDefaultActor: false,
          id: feedbackId,
        },
      };

      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Send add friend request
   */
  getAddFriendRequest(newFriendId) {
    try {
      if (!newFriendId) {
        return null;
      }

      const targetId = `${newFriendId}`;

      const headers = this.getHeaders();
      const currentUserId = `${this.cookies?.i_user || this.cookies?.c_user}`;
      const extraPayload = {
        doc_id: 7707897945967331,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: {
          // input: {
          //   attribution_id_v2:
          //     'ProfileCometTimelineListViewRoot.react,comet.profile.timeline.list,via_cold_start,',
          //   friend_requestee_ids: [targetId],
          //   friending_channel: 'PROFILE_BUTTON',
          //   warn_ack_for_ids: [],
          //   actor_id: currentUserId,
          //   client_mutation_id: '1',
          // },
          // scale: 1,
          input: {
            attribution_id_v2: 'ProfileCometTimelineListViewRoot.react,comet.profile.timeline.list,unexpected',
            friend_requestee_ids: [targetId],
            friending_channel: 'PROFILE_BUTTON',
            warn_ack_for_ids: [targetId],
            actor_id: currentUserId,
            client_mutation_id: '6',
          },
          scale: 1,
        },
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get list spin comments
   * @param {Array} comments
   * @param {Array} groupIds
   * @return {Array}
   */
  checkPageProfile(targetId = null) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: { scale: 1, userID: `${targetId || this.cookies?.i_user || this.cookies?.c_user}` },
        server_timestamps: true,
        doc_id: 7113883452072861,
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * get Video Upload Configs
   * @param {number | string} actorID
   * @return {object}
   */
  getVideoUploadConfigs(targetID = null) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        doc_id: 5083329358376602,
        variables: {
          actorID: `${this.cookies?.i_user || this.cookies?.c_user}`,
          entryPoint: 'group',
          targetID: targetID || '',
        },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * declare Video Upload Infomation
   * @param {*} declareVideoUri
   * @param {*} waterfallId
   * @param {*} fileSize
   * @param {*} fileExtension
   * @param {*} actorID
   * @return {object}
   */
  declareVideoUploadInfomation(declareVideoUri, waterfallId, fileSize, fileExtension, actorID = null) {
    try {
      const headers = {
        accept: '*/*',
        'accept-language': 'en-US,en;q=0.9',
        'cache-control': 'no-cache',
        'content-type': 'application/x-www-form-urlencoded',
        cookie: this.getCookieString(),
        origin: 'https://www.facebook.com',
        pragma: 'no-cache',
        priority: 'u=1, i',
        referer: 'https://www.facebook.com',
        'sec-ch-ua': `"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"`,
        'sec-ch-ua-mobile': `?0`,
        'sec-ch-ua-platform': 'Windows',
        'sec-fetch-dest': `empty`,
        'sec-fetch-mode': `cors`,
        'sec-fetch-site': `same-site`,
        'user-agent': `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36`,
        x_fb_video_waterfall_id: waterfallId,
      };

      const payload = {
        file_size: fileSize,
        file_extension: fileExtension,
        target_id: actorID || this.cookies?.i_user || this.cookies?.c_user,
        source: 'composer',
        composer_dialog_version: '',
        waterfall_id: waterfallId,
        composer_session_id: waterfallId,
        composer_entry_point_ref: 'group',
        composer_work_shared_draft_mode: '',
        has_file_been_replaced: false,
        supports_chunking: true,
        supports_file_api: true,
        partition_start_offset: 0,
        partition_end_offset: fileSize,
        creator_product: 2,
        spherical: false,
        video_publisher_action_source: '',
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
      };

      return {
        endpoint: `${declareVideoUri}?__a=1`,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * open Container For Upload
   * @param {number} start_offset
   * @param {number} end_offset
   * @param {number | string} actorID
   * @return {object}
   */
  openContainerForUpload(containerServiceUri, hashKey, start_offset, end_offset, actorID = null) {
    try {
      const targetId = actorID || this.cookies?.i_user || this.cookies?.c_user;
      const headers = {
        accept: '*/*',
        'accept-language': `en-US,en;q=0.9`,
        'cache-control': `no-cache`,
        cookie: this.getCookieString(),
        origin: `https:////www.facebook.com`,
        pragma: `no-cache`,
        priority: `u=1, i`,
        referer: `https://www.facebook.com`,
        'sec-ch-ua': `"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"`,
        'sec-ch-ua-mobile': `?0`,
        'sec-ch-ua-platform': 'Windows',
        'sec-fetch-dest': `empty`,
        'sec-fetch-mode': `cors`,
        'sec-fetch-site': `same-site`,
        'user-agent': `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36`,
      };
      const payload = {};

      const paramsObj = {
        __aaid: 0,
        __user: targetId,
        __a: 1,
        __dyn: this.dyn,
        __comet_req: 15,
        fb_dtsg_ag: this.fbDtsg,
      };

      // do not supply headers so that browser can set it automatically
      const searchParams = new URLSearchParams(paramsObj);

      return {
        endpoint: `${containerServiceUri}/fb_video/${hashKey}-${start_offset}-${end_offset}?${searchParams}`,
        method: 'GET',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * append Video Data
   * @param {number} start_offset
   * @param {number} end_offset
   * @param {number | string} videoId
   * @param {number | string} actorID
   * @return {object}
   */
  appendVideoData(start_offset, end_offset, videoId, actorID = null) {
    try {
      const targetId = actorID || this.cookies?.i_user || this.cookies?.c_user;
      const headers = this.getHeaders({ 'X-Entity-Name': 'undefined' });
      const payload = {};

      const hashKey = convertToMD5(targetId);

      return {
        endpoint: `https://rupload-hkg1-2.up.facebook.com/fb_video/${hashKey}-${start_offset}-${end_offset}?__user=${targetId}&__a=1&fb_dtsg=${this.fbDtsg}&reel_video=${videoId}`,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * mark As Received Video Upload Progress
   * @param {number | string} groupId
   * @param {string} waterfallId
   * @param {number} videoSize
   * @param {string} videoId
   * @param {string} fbuploaderVideoFileChunk
   * @param {number | string} actorID
   * @return {object}
   */
  markAsReceivedVideoUploadProgress(receiveUri, waterfallId, videoSize, videoId, fbuploaderVideoFileChunk) {
    try {
      const targetId = this.cookies?.i_user || this.cookies?.c_user;
      const headers = {
        accept: '*/*',
        'accept-language': `en-US,en;q=0.9`,
        'cache-control': `no-cache`,
        cookie: this.getCookieString(),
        origin: `https://www.facebook.com`,
        pragma: `no-cache`,
        priority: `u=1, i`,
        referer: `https://www.facebook.com`,
        'sec-ch-ua': `"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"`,
        'sec-ch-ua-mobile': `?0`,
        'sec-ch-ua-platform': 'Windows',
        'sec-fetch-dest': `empty`,
        'sec-fetch-mode': `cors`,
        'sec-fetch-site': `same-site`,
        'user-agent': `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36`,
        x_fb_video_waterfall_id: waterfallId,
      };
      const payload = {
        fbuploader_video_file_chunk: fbuploaderVideoFileChunk,
      };

      const paramsObj = {
        composer_session_id: waterfallId,
        video_id: videoId,
        start_offset: 0,
        end_offset: videoSize,
        source: 'composer',
        target_id: targetId,
        waterfall_id: waterfallId,
        composer_entry_point_ref: 'group',
        has_file_been_replaced: false,
        supports_chunking: true,
        partition_start_offset: 0,
        partition_end_offset: videoSize,
        __aaid: 0,
        __user: targetId,
        __a: 1,
        __dyn: this.dyn,
        __comet_req: 15,
        fb_dtsg: this.fbDtsg,
      };

      const searchParams = new URLSearchParams(paramsObj);

      return {
        endpoint: `${receiveUri}?${searchParams}&composer_work_shared_draft_mode&composer_dialog_version&upload_speed`,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get payload for list pending posts in the group
   * @param {number | string} groupId
   * @param {null | string} cursor
   * @return {object}
   */
  getPendingPostsInGroupPayload(groupId, cursor = null, amount = 10) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        doc_id: 8052539111451857,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: {
          content_type: 'PENDING',
          count: amount,
          cursor,
          feedLocation: 'GROUPS_VIEWER_CONTENT_PENDING_POSTS',
          feedbackSource: null,
          focusCommentID: null,
          ordering: 'NEWEST_FIRST',
          privacySelectorRenderLocation: 'COMET_STREAM',
          scale: 1,
          useDefaultActor: false,
          id: groupId,
        },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * get payload for remove peding post
   * @param {string} storyId
   * @return {object}
   */
  getRemovePendingPostPayload(storyId) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload({
        doc_id: 7380390182090193,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: {
          input: {
            story_id: storyId,
            story_location: 'GROUP',
            actor_id: this.cookies?.i_user || this.cookies?.c_user,
            client_mutation_id: '2',
          },
          groupID: null,
          inviteShortLinkKey: null,
          renderLocation: null,
          scale: 1,
        },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get timeline posts of friend
   * @param {string} userId
   * @param {string} cursor
   */
  getTimelinePostsPayload(userId, cursor = null) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __req: 'p',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ProfileCometTimelineFeedRefetchQuery',
        variables: {
          afterTime: null,
          beforeTime: null,
          count: 3,
          ...(cursor && { cursor }),
          feedLocation: 'TIMELINE',
          feedbackSource: 0,
          focusCommentID: null,
          memorializedSplitTimeFilter: null,
          omitPinnedPost: true,
          postedBy: null,
          privacy: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'timeline',
          scale: 1,
          stream_count: 10,
          taggedInOnly: null,
          trackingCode: null,
          useDefaultActor: false,
          id: userId,
          __relay_internal__pv__CometImmersivePhotoCanUserDisable3DMotionrelayprovider: false,
          __relay_internal__pv__IsWorkUserrelayprovider: false,
          __relay_internal__pv__IsMergQAPollsrelayprovider: false,
          __relay_internal__pv__CometUFIReactionsEnableShortNamerelayprovider: false,
          __relay_internal__pv__CometUFIShareActionMigrationrelayprovider: true,
          __relay_internal__pv__IncludeCommentWithAttachmentrelayprovider: true,
          __relay_internal__pv__StoriesArmadilloReplyEnabledrelayprovider: true,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
        server_timestamps: true,
        doc_id: '26837519385846475',
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get timeline posts of page
   * @param {string} cursor
   */
  getTimelinePostsOfPagePayload(from = reduceMinutes(new Date(), 60 * 24 * 30 * 4), to = new Date(), cursor = null) {
    try {
      const headers = this.getHeaders();
      const pageId = this.cookies?.i_user || this.cookies?.c_user;
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: pageId,
        __user: pageId,
        __a: 1,
        __req: 'p',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ProfileCometTimelineFeedRefetchQuery',
        variables: {
          beforeTime: parseInt(to.getTime() / 1000),
          afterTime: parseInt(from.getTime() / 1000),
          count: 3,
          cursor,
          feedLocation: 'TIMELINE',
          feedbackSource: 0,
          focusCommentID: null,
          memorializedSplitTimeFilter: null,
          omitPinnedPost: true,
          postedBy: { group: 'OWNER' },
          privacy: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'timeline',
          scale: 1,
          stream_count: 1,
          taggedInOnly: null,
          trackingCode: null,
          useDefaultActor: false,
          id: pageId,
          __relay_internal__pv__CometImmersivePhotoCanUserDisable3DMotionrelayprovider: false,
          __relay_internal__pv__IsWorkUserrelayprovider: false,
          __relay_internal__pv__IsMergQAPollsrelayprovider: false,
          __relay_internal__pv__CometUFIReactionsEnableShortNamerelayprovider: false,
          __relay_internal__pv__CometUFIShareActionMigrationrelayprovider: true,
          __relay_internal__pv__IncludeCommentWithAttachmentrelayprovider: true,
          __relay_internal__pv__StoriesArmadilloReplyEnabledrelayprovider: false,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
        server_timestamps: true,
        doc_id: '27642524632013717',
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get followers
   */
  getFollowers(cursor = null) {
    try {
      const headers = this.getHeaders();
      const pageId = this.cookies?.i_user || this.cookies?.c_user;
      const stringToEncode = `app_collection:${pageId}:2356318349:32`;
      const encodedString = btoa(stringToEncode);

      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: pageId,
        __user: pageId,
        __a: 1,
        dpr: 1,
        __req: 15,
        __comet_req: 15,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ProfileCometAppCollectionListRendererPaginationQuery',
        variables: {
          count: 8,
          cursor,
          scale: 1.5,
          search: null,
          id: encodedString,
        },
        server_timestamps: true,
        doc_id: '26791051487175741',
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get poke friend
   * @param {string} userId
   */
  getPokePayload(userId) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __req: '3f',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'PokesMutatorPokeMutation',
        variables: {
          input: { client_mutation_id: '1', actor_id: this.cookies?.i_user || this.cookies?.c_user, user_id: userId },
        },
        server_timestamps: true,
        doc_id: '5028133957233114',
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get like reaction
   * @param {string} feedbackId
   */
  getLikeReactionPayload(feedbackId) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __req: '3f',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'CometUFIFeedbackReactMutation',
        variables: {
          input: {
            feedback_id: feedbackId,
            feedback_reaction_id: '1635855486666999',
            feedback_source: 'PROFILE',
            actor_id: this.cookies?.i_user || this.cookies?.c_user,
            client_mutation_id: '1',
          },
          useDefaultActor: false,
          __relay_internal__pv__CometUFIReactionsEnableShortNamerelayprovider: false,
        },
        server_timestamps: true,
        doc_id: '8030707243693006',
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Remove friend
   * @param {string} friendId
   */
  getRemoveFriendPayload(friendId) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __req: '3f',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'FriendingCometUnfriendMutation',
        variables: {
          input: {
            source: 'bd_profile_button',
            unfriended_user_id: friendId,
            actor_id: this.cookies?.i_user || this.cookies?.c_user,
            client_mutation_id: '4',
          },
          scale: 1,
        },
        server_timestamps: true,
        doc_id: '8752443744796374',
      };
      const payload = this.getPayload(extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get cookie string.
   * @return {string}
   */
  getCookieString() {
    return Object.keys(this.cookies)
      ?.map((key) => this.cookies[key])
      .join('; ');
  }

  /**
   * Get headers.
   * @param {object}extraHeaders
   * @return {object}
   */
  getHeaders(extraHeaders = {}) {
    const cookieString = this.getCookieString();
    return {
      accept: `*/*`,
      'accept-language': 'en-US,en;q=0.9',
      'content-type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      cookie: cookieString,
      dpr: 1,
      origin: 'https://www.facebook.com',
      referer: 'https://www.facebook.com',
      'sec-ch-prefers-color-scheme': 'light',
      'sec-ch-ua': `"Google Chrome";v="123", "Not:A-Brand";v="8", "Chromium";v="123"`,
      'sec-ch-ua-full-version-list': `"Google Chrome";v="123.0.6312.106", "Not:A-Brand";v="8.0.0.0", "Chromium";v="123.0.6312.106"`,
      'sec-ch-ua-mobile': '?0',
      'sec-ch-ua-model': '',
      'sec-ch-ua-platform': 'Windows',
      'sec-ch-ua-platform-version': '10.0.0',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'same-origin',
      'user-agent':
        'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36',
      'viewport-width': 1920,
      ...extraHeaders,
    };
  }

  /**
   * Get payload.
   * @param {object} extraPayload
   * @return {object}
   */
  getPayload(extraPayload = {}) {
    /**
     * Must have: fb_dtsg
     */
    return {
      fb_dtsg: this.fbDtsg,
      server_timestamps: true,
      ...(this.otherData || {}),
      ...extraPayload,
    };
  }
}
